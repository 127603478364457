<!--  -->
<template>
  <div>
    <div class="foot"><img src="../assets/dx-bg.webp" /></div>
    <van-result type="success"
                tip="你的资料提交成功，等待快递小哥给您送货上门。"
                title="成功" />
  </div>
</template>
<style lang='less' scoped>
.foot {
  display: flex;
  img {
    width: 100%;
  }
}
</style>
<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>