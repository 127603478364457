import 'whatwg-fetch'
export default {
  dataApiUrl: 'https://haoma.shuda.co/',
  createRequest (data, method) {
    if (method == null || method === '') {
      method = 'POST'
    }
    data = JSON.stringify(data)
    const options = {
      method: method,
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'api-NnZbOn8QakBCJlHTWJoGW6Kw-yang'
      },
      responseType: 'json'
    }
    return options
  },
  get (funApi) {
    return new Promise((resolve, reject) => {
      fetch(funApi)
        .then(response => {
          return response.json()
        })
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  post (funApi, data) {
    let targetUrl = ''
    if (funApi.indexOf('http') < 0) {
      targetUrl = this.dataApiUrl + funApi
    } else {
      targetUrl = funApi
    }
    const options = this.createRequest(data, 'post')
    return new Promise((resolve, reject) => {
      fetch(targetUrl, options).then(function(res) {
        const result = res.json()
        return result
      }).then(function(json) {
        if (json.code === 401) {
          reject(json)
        } else {
          resolve(json)
        }
      }).catch(function(ex) {
        reject(ex)
      })
    })
  }
}
