import http from '../common/http'
export default {
  async getProvince () {
    let provinceList = []
    let cityList = []
    let ids = []
    const result = await http.post('getpcs', {})
    result.forEach(element => {
      const pid = element.provinceId
      const pname = element.provinceName
      const cid = element.cityId
      const cname = element.cityName
      if (ids.indexOf(pid) < 0) {
        provinceList.push({
          code: pid,
          name: pname
        })
        ids.push(pid)
      }
      if (ids.indexOf(cid) < 0) {
        cityList.push({
          pid: pid,
          code: cid,
          name: cname
        })
        ids.push(cid)
      }
    });
    return { provinceList, cityList }
  },

  async getDistricts (data) {
    let districtsList = []
    const result = await http.post('getdistricts', data)
    result.forEach(element => {
      const pid = data.provinceId
      const cid = data.cityId
      const disid = element.districtId
      const disname = element.districtName
      districtsList.push({
        pid: pid,
        cid: cid,
        code: disid,
        name: disname
      })
    });
    return districtsList
  }
}
