<!--  -->
<template>
  <div class="input-info">
    <!-- <div class="foot"><img src="../assets/dx-logo.png" /></div> -->
    <div class="foot"><img src="../assets/dx-bg.webp" /></div>
    <div class="tip">
      <div>
        <h2>请填写办卡信息</h2>
      </div>
      <div class="tip-r">根据国家实名制要求，请确保办理人信息真实有效</div>
      <div class="tip-r">电信产品根据市场有可能会变动,最终以激活时为准</div>
      <div class="tip-r">服务热线:10000</div>
    </div>
    <div class="line"></div>
    <van-cell-group>
      <van-field v-model="info.cardName"
                 label="姓名"
                 required
                 clearable
                 maxlength="30"
                 :label-width="100" />
      <template v-if="!clickPhone">
        <van-field v-model="disReceiverPhone"
                   readonly
                   required
                   clearable
                   label="联系电话"
                   @click="onClickPhone"
                   maxlength="11"
                   :label-width="100" />
      </template>
      <template v-else>
        <van-field v-model="info.receiverPhone"
                   type="number"
                   required
                   clearable
                   label="联系电话"
                   maxlength="11"
                   :label-width="100" />
      </template>
      <van-field v-model="info.cardId"
                 required
                 label="身份证号"
                 clearable
                 maxlength="18"
                 :label-width="100" />
      <van-field v-model="areaInfo"
                 required
                 label="送卡地址"
                 clearable
                 readonly
                 is-link
                 :label-width="100"
                 @click="onShowArea" />
      <van-field type="textarea"
                 v-model="info.address"
                 placeholder="请输入收卡详细地址"
                 rows="3"
                 clearable
                 autosize
                 required />
    </van-cell-group>
    <van-popup v-model="showArea"
               position="bottom"
               closeable
               :style="{ height: '400px' }"
               :show-toolbar="true">
      <div class="area-list">
        <div class="col">
          <van-picker :columns="provinceList"
                      :loading="loadingCity"
                      :default-index="defProvinceIndex"
                      :visible-item-count="10"
                      value-key="name"
                      @change="onChange1" />
        </div>
        <div class="col">
          <van-picker :columns="cityList"
                      :loading="loadingCity"
                      :default-index="defCityIndex"
                      value-key="name"
                      :visible-item-count="10"
                      @change="onChange2" />
        </div>
        <div class="col">
          <van-picker :columns="districtList"
                      :loading="loadingCity"
                      :default-index="defDistrictIndex"
                      value-key="name"
                      @confirm="onConfirmArea"
                      :visible-item-count="10"
                      @change="onChange3" />
        </div>
      </div>
    </van-popup>
    <div class="btn-box">
      <van-button type="info"
                  @click="onSave">立即提交，免费送卡上门</van-button>
    </div>
  </div>
</template>
<style lang='less' scoped>
/deep/.area-list {
  display: flex;
  .col {
    flex: 1 1 0;
    min-width: 0;
  }
}
.btn-box {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.foot {
  display: flex;
  img {
    width: 100%;
  }
}
.line {
  height: 1px;
  background-color: #e2e2e2;
}
.tip {
  padding: 0px 20px;
  margin-top: -10px;
  background-color: #fff;
  padding-bottom: 20px;
  .tip-r {
    margin-top: 5px;
    color: #555;
    font-size: 13px;
  }
}
.input-info {
  text-align: left;
}
</style>
<script>
import city from '../common/city'
import http from '../common/http'
import { Notify, Toast } from 'vant-green'
export default {
  data () {
    return {
      showArea: false,
      loadingCity: false,
      areaList: null,
      areaCode: '440308',
      loading: false,
      clickPhone: false,
      info: {
        userid: '',
        cardName: '',
        receiverPhone: '',
        cardId: '',
        sendProvinceId: '',
        sendProvinceName: '',
        sendCityId: '',
        sendCityName: '',
        sendDistrictId: '',
        sendDistrictName: '',
        address: ''
      },
      areaInfo: '',
      provinceList: [],
      defProvinceIndex: 0,
      cityList: [],
      defCityIndex: 0,
      districtList: [],
      defDistrictIndex: 0
    }
  },
  components: {

  },
  computed: {
    disReceiverPhone () {
      const phone = this.info.receiverPhone
      if (phone && phone.length >= 11) {
        const disPhone = phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
        return disPhone
      }
      return phone
    }
  },
  mounted () {
    this.saveLog()
    this.loadArea()
    this.loadUserInfo()
  },
  methods: {
    saveLog () {
      const uid = this.$route.query.userId
      http.post('savelog', { userid: uid, pagename: 'input' })
    },
    loadUserInfo () {
      if (this.$route.query && this.$route.query.userId) {
        const uid = this.$route.query.userId
        if (!uid) {
          uid = ''
        }
        if (uid !== '') {
          this.info.userid = uid
          http.post('getuserinfo', { userid: uid }).then(res => {
            if (res.code === 0) {
              if (res.data) {
                if (res.data.name) {
                  this.info.cardName = res.data.name
                }
                if (res.data.phone) {
                  this.info.receiverPhone = res.data.phone
                }
              }
            }
          })
        }
      }
    },
    onClickPhone () {
      this.clickPhone = true
    },
    async loadArea () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      this.loadingCity = true
      this.areaList = await city.getProvince()

      let defProvince = null
      for (let i = 0; i < this.areaList.provinceList.length; i++) {
        if (this.areaList.provinceList[i].name === '广东省') {
          defProvince = this.areaList.provinceList[i]
          this.defProvinceIndex = i
          break
        }
      }

      const citys = this.areaList.cityList
      const sCitys = []
      for (let i = 0; i < citys.length; i++) {
        if (citys[i].pid === defProvince.code) {
          sCitys.push(citys[i])
        }
      }

      let defCity = null
      for (let i = 0; i < sCitys.length; i++) {
        if (sCitys[i].name === '深圳市') {
          defCity = sCitys[i]
          this.defCityIndex = i
          break
        }
      }
      const disList = await city.getDistricts({ provinceId: defProvince.code, cityId: defCity.code })

      this.provinceList = this.areaList.provinceList
      this.cityList = sCitys
      this.districtList = disList


      this.defDistrictIndex = 0

      this.info.sendProvinceId = defProvince.code
      this.info.sendProvinceName = defProvince.name
      this.info.sendCityId = defCity.code
      this.info.sendCityName = defCity.name
      this.info.sendDistrictId = disList[0].code
      this.info.sendDistrictName = disList[0].name

      this.areaInfo = this.info.sendProvinceName + ' ' + this.info.sendCityName + ' ' + this.info.sendDistrictName

      this.loadingCity = false
      Toast.clear();
    },
    async onChange3 (picker, value, index) {
      if (index !== this.defDistrictIndex) {
        this.defDistrictIndex = index
        this.info.sendDistrictId = value.code
        this.info.sendDistrictName = value.name
      }
      this.areaInfo = this.info.sendProvinceName + ' ' + this.info.sendCityName + ' ' + this.info.sendDistrictName
    },
    async onChange2 (picker, value, index) {
      this.loadingCity = true
      if (index !== this.defCityIndex) {
        const disList = await city.getDistricts({ provinceId: this.info.sendProvinceId, cityId: value.code })
        this.districtList = disList

        this.defCityIndex = index
        this.defDistrictIndex = 0

        this.info.sendCityId = value.code
        this.info.sendCityName = value.name
        this.info.sendDistrictId = disList[0].code
        this.info.sendDistrictName = disList[0].name

      }
      this.areaInfo = this.info.sendProvinceName + ' ' + this.info.sendCityName + ' ' + this.info.sendDistrictName
      this.loadingCity = false
    },
    async onChange1 (picker, value, index) {
      this.loadingCity = true
      if (index !== this.defProvinceIndex) {
        const citys = this.areaList.cityList
        const sCitys = []
        for (let i = 0; i < citys.length; i++) {
          if (citys[i].pid === value.code) {
            sCitys.push(citys[i])
          }
        }
        const disList = await city.getDistricts({ provinceId: value.code, cityId: sCitys[0].code })
        this.cityList = sCitys
        this.districtList = disList

        this.defProvinceIndex = index
        this.defCityIndex = 0
        this.defDistrictIndex = 0

        this.info.sendProvinceId = value.code
        this.info.sendProvinceName = value.name
        this.info.sendCityId = sCitys[0].code
        this.info.sendCityName = sCitys[0].name
        this.info.sendDistrictId = disList[0].code
        this.info.sendDistrictName = disList[0].name

      }
      this.areaInfo = this.info.sendProvinceName + ' ' + this.info.sendCityName + ' ' + this.info.sendDistrictName
      this.loadingCity = false
    },
    onSave () {
      if (this.$route.query) {
        let key = ''
        if (this.$route.query.key) {
          key = this.$route.query.key
        } else {
          if (this.$route.query.t) {
            key = this.$route.query.t
          }
        }
        this.info.key = key
      }
      if (this.info.cardName.trim() === '') {
        Notify({ type: 'danger', message: '姓名不能为空' })
        return
      }
      if (this.info.receiverPhone.trim() === '') {
        Notify({ type: 'danger', message: '联系电话不能为空' })
        return
      }
      if (this.info.cardId.trim() === '') {
        Notify({ type: 'danger', message: '身份证号不能为空' })
        return
      }
      if (this.areaInfo.trim() === '' || this.info.address.trim() === '') {
        Notify({ type: 'danger', message: '送卡地址不能为空' })
        return
      }
      if (!this.validatePhone(this.info.receiverPhone.trim())) {
        Notify({ type: 'danger', message: '手机号码格式不正确,请检查输入是否正确' })
        return
      }
      if (!this.validateIDCard(this.info.cardId.trim())) {
        Notify({ type: 'danger', message: '身份证号格式不正确,请检查输入是否正确' })
        return
      }
      if (!this.loading) {
        this.loading = true
        Toast.loading({
          message: '正在提交数据...',
          duration: 0,
          forbidClick: true
        });

        http.post('saveorder', this.info).then(res => {
          Toast.clear()
          this.loading = false
          if (res.code === 0) {
            this.$router.push('/result')
          } else {
            Notify({ type: 'danger', message: res.msg })
          }
        })
      }
    },
    onShowArea () {
      this.showArea = true
    },
    onClose () {
      this.showArea = false
    },
    onConfirmArea (val) {
      if (val) {
        this.info.area = val[0].name + ' ' + val[1].name + ' ' + val[2].name
      }
      this.showArea = false
    },

    validatePhone (phoneNumber) {
      const phoneRegex = /^1[3-9]\d{9}$/
      // 测试输入的手机号码是否匹配正则表达式
      return phoneRegex.test(phoneNumber)
    },
    validateIDCard (idCard) {
      // 定义正则表达式
      const regex = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i

      // 测试输入的身份证号码是否匹配正则表达式
      if (!regex.test(idCard)) {
        return false
      }

      // 验证校验码
      const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

      let sum = 0
      for (let i = 0; i < 17; i++) {
        sum += idCard[i] * weights[i]
      }

      const mod = sum % 11

      if (checkCodes[mod] !== idCard[17].toUpperCase()) {
        return false
      }

      return true
    }

  }
}
</script>
