<!--  -->
<template>
  <div class="page">
    <div class="top-title">
      <div class="bg-box">
        司机专用卡
      </div>
    </div>
    <div class="header">
      <img src="../assets/top1-2.jpg" />
    </div>
    <div class="taocan">
      <img src="../assets/2v2.jpg" />
    </div>
    <div class="app-list">
      <div class="app-box">
        <div class="h-title">出行无忧，下面APP不再担心流量不够用</div>
        <div class="a-list">
          <img src="../assets/app-list.jpg" />
        </div>
      </div>
    </div>
    <div class="app-list">
      <div class="app-box">
        <div class="h-title">首充100元活动介绍</div>
        <div class="a-list">
          <div>1、拿到卡激活后立即<span style="color:#f6312d">送</span>40元话费</div>
          <div>
            2、首充100元，<span style="color:#f6312d">送</span>240元共计340元，<span style="color:#f6312d">送</span>100GB/月通用流量，<span style="color:#f6312d">送</span>100分钟/月国内语音通话。
            <p style="font-size:12px;color:#666;margin-top:10px;">说明：赠送的240元每月到账10元，连续送2年</p>
          </div>
          <!-- <div>3、此电话卡参与100元首充活动后巨划算</div> -->
        </div>
      </div>
    </div>
    <div class="app-list">
      <div class="app-box">
        <div class="h-title">套餐详情</div>
        <div class="a-list zf">
          <div class="row">
            <div class="title">
              领卡
            </div>
            <div class="remark">
              <div>0元领卡，0运费，0首月，0套路</div>
            </div>
          </div>
          <div class="row">
            <div class="title">
              月费
            </div>
            <div class="remark">
              29元/月 (2年)<br>
              2年后恢复原价39元/月
            </div>
          </div>
          <div class="row">
            <div class="title">
              流量
            </div>
            <div class="remark">
              <div>135GB/月 （永久）<br />
                100多款主流APP专属流量
              </div>
            </div>
          </div>
          <div class="row">
            <div class="title">
              通话
            </div>
            <div class="remark">
              100分钟/月 （永久）
            </div>
          </div>
          <div class="row">
            <div class="title">
              短信
            </div>
            <div class="remark">
              0.1元/条（永久）
            </div>
          </div>
          <div class="row">
            <div class="title">
              接听
            </div>
            <div class="remark">
              免费（永久）
            </div>
          </div>
          <div class="row">
            <div class="title">
              来电显示
            </div>
            <div class="remark">
              免费（永久）
            </div>
          </div>
          <div class="row">
            <div class="title">
              其他
            </div>
            <div class="remark">
              无任何隐藏费用，0套路
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-list">
      <div class="app-box">
        <div class="h-title">常见问题</div>
        <div class="a-list zf">
          <div class="row">
            <div class="remark"
                 style="padding:15px 20px;">
              <div>
                问：这么便宜的套餐,不会骗人的吧?
              </div>
              <div style="margin-top:10px;">
                此卡的原费用39元/月，流量才30GB/月，现与中国电信合作，推出首充100元的活动，激活后充值100元就能享有这么便宜的套餐，为广大司机们降低出行成本
              </div>
            </div>
          </div>
          <div class="row">
            <div class="remark"
                 style="padding:15px 20px;">
              <div>
                问：请问怎么拿到卡?
              </div>
              <div style="margin-top:10px;">
                领取时，按国家规定需要输入实名信息，以及收货地址，快递会按照地址免费配送到家
              </div>
            </div>
          </div>
          <div class="row">
            <div class="remark"
                 style="padding:15px 20px;">
              <div>
                问：拿到卡时，我不想要了，如何取消？
              </div>
              <div style="margin-top:10px;">
                无需取消，拿到卡后，不想要了，不激活即可，无需任何费用。
              </div>
            </div>
          </div>

          <div class="row">
            <div class="remark"
                 style="padding:15px 20px;">
              <div>
                问：电话卡使用问题怎么咨询?
              </div>
              <div style="margin-top:10px;">
                具体可致电中国电信客服电话10000。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dx-text">
      <img src="../assets/dx-s-logo.png" />
    </div>
    <div style="height:80px;"></div>
    <div class="btn-submit">
      <van-button type="warning"
                  @click="onInput">好评率99%，0元领卡</van-button>
      <div class="icon-click">
        <img src="../assets/fill_click.png" />
      </div>
    </div>

  </div>
</template>
<style lang='less' scoped>
/deep/.van-button--normal {
  flex: 1;
  box-sizing: border-box;
  margin: 0px 20px;
  font-weight: bold;
}
.dx-text {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  img {
    width: 50px;
  }
}
.page {
  background: linear-gradient(#f6312d 3.38%, #ec001b 46%, #ec001b);
  text-align: center;
  .top-title {
    position: absolute;
    top: 30px;
    width: 100%;
    color: #fff;
    z-index: 99;
    font-size: 26px;
    font-weight: 600;
    .bg-box {
      position: relative;
      background-color: #f9911d;
      width: 230px;
      padding: 5px 0px;
      margin: 0px auto;
      border-radius: 10px;
    }
  }
  .header {
    width: 100%;
    height: 425px;
    position: relative;
    margin-top: -50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: clip;
    }
  }
  .taocan {
    padding: 0px 20px;
    position: relative;
    margin-top: -10px;
    img {
      width: 100%;
      object-fit: cover;
      overflow: clip;
    }
  }
  .app-list {
    padding: 20px;
    .app-box {
      border-radius: 20px;
      overflow: hidden;
      .h-title {
        background-color: #ffe6bd;
        color: #ea0519;
        padding: 10px 0px;
        font-weight: 500;
      }
      .zf {
        padding: 0px !important;
        .row {
          margin-bottom: -1px !important;
          .title {
            border-right: 1px solid #f0e8dd;
            padding: 20px 0px;
          }
          .remark {
            font-size: 13px;
            padding: 0px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        div {
          margin-bottom: 0px !important;
        }
      }
      .a-list {
        background-color: #fef6e9;
        padding: 15px;
        text-align: left;
        .row {
          display: flex;
          border: 1px solid #f0e8dd;
          .title {
            width: 100px;
            text-align: center;
          }
          .remark {
          }
        }
        img {
          width: 100%;
        }
        div {
          line-height: 25px;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }
    }
  }
  .btn-submit {
    display: flex;
    position: fixed;
    bottom: 0px;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 10px;
    .icon-click {
      position: absolute;
      width: 30px;
      right: 30px;
      margin-top: 8px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
<script>
import http from '../common/http'
export default {
  data () {
    return {
      showInput: true,
      userId: ''
    }
  },
  components: {

  },
  computed: {

  },
  mounted () {
    this.userId = this.$route.query.userId
    this.saveLog()
  },
  methods: {
    saveLog () {
      http.post('savelog', { userid: this.userId, pagename: 'home' })
    },
    onInput () {
      this.$router.push({ path: '/info', query: { userId: this.userId } })
    }
  }
}
</script>
