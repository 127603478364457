import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import InputInfo from '../components/InputInfo.vue'
import Result from '../components/Result.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '免费领取流量卡'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '免费领取流量卡'
    }
  },
  {
    path: '/info',
    name: 'InputInfo',
    component: InputInfo,
    meta: {
      title: '免费领取流量卡'
    }
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
    meta: {
      title: '免费领取流量卡'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '免费领取流量卡'
  }
  next();
});

export default router
