var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticStyle:{"height":"80px"}}),_c('div',{staticClass:"btn-submit"},[_c('van-button',{attrs:{"type":"warning"},on:{"click":_vm.onInput}},[_vm._v("好评率99%，0元领卡")]),_vm._m(8)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-title"},[_c('div',{staticClass:"bg-box"},[_vm._v(" 司机专用卡 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("../assets/top1-2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"taocan"},[_c('img',{attrs:{"src":require("../assets/2v2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-list"},[_c('div',{staticClass:"app-box"},[_c('div',{staticClass:"h-title"},[_vm._v("出行无忧，下面APP不再担心流量不够用")]),_c('div',{staticClass:"a-list"},[_c('img',{attrs:{"src":require("../assets/app-list.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-list"},[_c('div',{staticClass:"app-box"},[_c('div',{staticClass:"h-title"},[_vm._v("首充100元活动介绍")]),_c('div',{staticClass:"a-list"},[_c('div',[_vm._v("1、拿到卡激活后立即"),_c('span',{staticStyle:{"color":"#f6312d"}},[_vm._v("送")]),_vm._v("40元话费")]),_c('div',[_vm._v(" 2、首充100元，"),_c('span',{staticStyle:{"color":"#f6312d"}},[_vm._v("送")]),_vm._v("240元共计340元，"),_c('span',{staticStyle:{"color":"#f6312d"}},[_vm._v("送")]),_vm._v("100GB/月通用流量，"),_c('span',{staticStyle:{"color":"#f6312d"}},[_vm._v("送")]),_vm._v("100分钟/月国内语音通话。 "),_c('p',{staticStyle:{"font-size":"12px","color":"#666","margin-top":"10px"}},[_vm._v("说明：赠送的240元每月到账10元，连续送2年")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-list"},[_c('div',{staticClass:"app-box"},[_c('div',{staticClass:"h-title"},[_vm._v("套餐详情")]),_c('div',{staticClass:"a-list zf"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 领卡 ")]),_c('div',{staticClass:"remark"},[_c('div',[_vm._v("0元领卡，0运费，0首月，0套路")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 月费 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 29元/月 (2年)"),_c('br'),_vm._v(" 2年后恢复原价39元/月 ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 流量 ")]),_c('div',{staticClass:"remark"},[_c('div',[_vm._v("135GB/月 （永久）"),_c('br'),_vm._v(" 100多款主流APP专属流量 ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 通话 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 100分钟/月 （永久） ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 短信 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 0.1元/条（永久） ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 接听 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 免费（永久） ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 来电显示 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 免费（永久） ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"title"},[_vm._v(" 其他 ")]),_c('div',{staticClass:"remark"},[_vm._v(" 无任何隐藏费用，0套路 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-list"},[_c('div',{staticClass:"app-box"},[_c('div',{staticClass:"h-title"},[_vm._v("常见问题")]),_c('div',{staticClass:"a-list zf"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"remark",staticStyle:{"padding":"15px 20px"}},[_c('div',[_vm._v(" 问：这么便宜的套餐,不会骗人的吧? ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 此卡的原费用39元/月，流量才30GB/月，现与中国电信合作，推出首充100元的活动，激活后充值100元就能享有这么便宜的套餐，为广大司机们降低出行成本 ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"remark",staticStyle:{"padding":"15px 20px"}},[_c('div',[_vm._v(" 问：请问怎么拿到卡? ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 领取时，按国家规定需要输入实名信息，以及收货地址，快递会按照地址免费配送到家 ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"remark",staticStyle:{"padding":"15px 20px"}},[_c('div',[_vm._v(" 问：拿到卡时，我不想要了，如何取消？ ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 无需取消，拿到卡后，不想要了，不激活即可，无需任何费用。 ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"remark",staticStyle:{"padding":"15px 20px"}},[_c('div',[_vm._v(" 问：电话卡使用问题怎么咨询? ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 具体可致电中国电信客服电话10000。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dx-text"},[_c('img',{attrs:{"src":require("../assets/dx-s-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-click"},[_c('img',{attrs:{"src":require("../assets/fill_click.png")}})])
}]

export { render, staticRenderFns }